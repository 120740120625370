import React from 'react';
import { List } from 'antd';
//se quito Avatar de antd


const Project = (props) =>{
    return(
      <List
        itemLayout="vertical"
        size="large"
        pagination={{
          onChange: page => {
            console.log(page);
          },
          pageSize: 4,
        }}
        dataSource={props.data}
        footer={
          <div>
            <b> </b>
          </div>
        }
        renderItem={item => (
          <List.Item
            key={item.title}
            actions={[
            //  <IconText type="star-o" text="156" />,
              //<IconText type="like-o" text="156" />,
              //<IconText type="message" text="2" />,
            ]}
            extra={
              <img
                width={272}
                height={150}
                alt="logo"
              /*  src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"*/
                src={`${item.image}`}
              />
            }
          >
            <List.Item.Meta
            //  avatar={<Avatar src={item.avatar} />}
              title={<a href={`/project/${item.id}`}>{item.title}</a>}
              description={item.description}
              location={item.location}
            />
            {item.content}
            {item.location}
          </List.Item>
        )}
      />
    )
}

export default Project;
