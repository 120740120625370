import React from 'react';
import { List } from 'antd';
//se quito Avatar de antd

const Product = (props) =>{
    return(
      <List
        itemLayout="vertical"
        size="large"
        pagination={{
          onChange: page => {
            console.log(page);
          },
          pageSize: 4,
        }}
        dataSource={props.data}
        footer={
          <div>
            <b> </b>
          </div>
        }
        renderItem={item => (
          <List.Item
            key={item.title}
            actions={[
            //  <IconText type="star-o" text="156" />,
              //<IconText type="like-o" text="156" />,
              //<IconText type="message" text="2" />,
            ]}
            extra={
              <img
                width={272}
                alt="logo"
                src={item.images}
              />
            }
          >
            <List.Item.Meta
            //  avatar={<Avatar src={item.avatar} />}
              title={<a href={`/product/${item.id}`}>{item.title}</a>}
              description={item.description}
              price={item.price}
            />
            {item.content}
            $ {item.price}
          </List.Item>
        )}
      />
    )
}

export default Product;
