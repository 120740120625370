import React from 'react';
import { Form, Input, Button, Upload, Icon} from 'antd';
import axios from 'axios';

const { TextArea } = Input;

class CustomForm extends React.Component{

  handleFormSubmit = async(event, requestType, projectID) =>{
      event.preventDefault();

      const projeObj = {
        title : event.target.elements.title.value,
        description : event.target.elements.description.value,
        location : event.target.elements.location.value
      }
        if (requestType === "post") {
        await axios.post('https://api.kuara-lite.com/api/projects/',projeObj)
          .then(res =>{
            if (res.status === 201) {
                this.props.history.push(`/project/`);
            }
          })
          .catch(err => console.log(err));


        } else if (requestType === "put") {
        await axios.put(`https://api.kuara-lite.com/api/projects/${projectID}`,projeObj)
          .then(res =>{
            if (res.status === 201) {
                this.props.history.push(`/project/`);
            }
          })
          .catch(err => console.log(err));

      }
  }
  render() {

    return (
      <div>
        <Form onSubmit={(event) =>
          this.handleFormSubmit(
            event,
            this.props.requestType,
            this.props.projectID
          )}>
          <Form.Item label="Title">
            <Input name="title" placeholder="Put a Title Here" />
          </Form.Item>
          <Form.Item label="Description">
            <TextArea name="description" placeholder="Put a Description Here" />
          </Form.Item>
          <Form.Item label="Location">
            <Input name="location" placeholder="Put a location Here" />
          </Form.Item>
          <Form.Item label="Image">
            <Upload name="image" action="/upload.do" listType="picture">
              <Button>
              <Icon type="file-image" /> Click to image
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">{this.props.btnText}</Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default CustomForm;
