import React from 'react';
import axios from 'axios';

import {Button, Card} from 'antd';
import CustomForm from '../components/Form';

class ProductDetail extends React.Component {

    state = {
      product : {}
    };

    componentDidMount(){
      const productID = this.props.match.params.productID;
        axios.get(`https://api.kuara-lite.com/api/products/${productID}`)
        .then(res => {
            this.setState({
              product: res.data
            });
            //console.log(res.data);
          });
    }
    handleDelete = (event) => {
        event.preventDefault();
         const productID = this.props.match.params.productID;
         axios.delete(`https://api.kuara-lite.com/api/products/${productID}`)
         .then(res =>{
           if (res.status === 204) {
               this.props.history.push(`/`);
           }
         })
         .catch(err => console.log(err));
     }
    render(){
        return(
          <div>
            <Card title = {this.state.product.title}>
              <p>{this.state.product.description}</p>
              <p>$ {this.state.product.price}</p>
            </Card>
            <br />
            <CustomForm
                {...this.props}
                requestType="put"
                productID={this.props.match.params.productID}
                btnText="Update"
            />
            <form onSubmit={this.handleDelete}>
              <Button type="danger" htmlType="submit">Delete</Button>
            </form>
          </div>
        )
    }
}

export default ProductDetail;
