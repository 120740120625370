import React from 'react';
import { Form, Input, Button} from 'antd';
import axios from 'axios';

const { TextArea } = Input;

class CustomForm extends React.Component{

  handleFormSubmit = async(event, requestType, productID) =>{
      event.preventDefault();
      const projeProd = {
        title : event.target.elements.title.value,
        description : event.target.elements.description.value,
        price : event.target.elements.price.value
      }
        if (requestType === "post") {
        await axios.post('https://api.kuara-lite.com/api/products/',projeProd)
          .then(res =>{
            if (res.status === 201) {
                this.props.history.push(`/`);
            }
          })
          .catch(err => console.log(err));

        } else if (requestType === "put") {
        await axios.put(`https://api.kuara-lite.com/api/products/${productID}`,projeProd)
          .then(res =>{
            if (res.status === 201) {
                this.props.history.push(`/`);
            }
          })
          .catch(err => console.log(err));
      }
  }
  render() {

    return (
      <div>
        <Form onSubmit={(event) =>
          this.handleFormSubmit(
            event,
            this.props.requestType,
            this.props.productID
          )}>
          <Form.Item label="Title">
            <Input name="title" placeholder="Put a Title Here" />
          </Form.Item>
          <Form.Item label="Description">
            <TextArea name="description" placeholder="Put a Description Here" />
          </Form.Item>
          <Form.Item label="Price">
            <Input name="price" type="number" placeholder="Put a Price Here" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">{this.props.btnText}</Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default CustomForm;
