import React, { Component } from 'react'
import axios from 'axios'

import { Form, Icon, Input, Button, Modal } from 'antd';
import { Redirect } from 'react-router-dom';

const AUTH_URL = 'https://api.kuara-lite.com/rest-auth/login/'

class Login extends Component {
 
    state = {
        username: '',
        password: '',
        isLoggedIn: false,
        loginFailed: false
    }

    handleChange = (e) => {
        e.preventDefault()
        let name = e.target.name
        let val = e.target.value
        this.setState({
            [name]: val
        })
    }

    handleLogin = (e) => {
        e.preventDefault()
        axios.post(AUTH_URL, {username: this.state.username, password:this.state.password})
        .then(res => {
            //console.log(res)
            if(res.status === 200) {
                localStorage.setItem('auth', 'true')
                this.setState({isLoggedIn:true})
                //console.log(res)
            } 
        })
        .catch(err => {
            this.setState({loginFailed:true})
        })
    }

    handleModal = () => {
        this.setState({loginFailed: !this.state.loginFailed})
    }

    render(){
        if(this.state.isLoggedIn) {
            return <Redirect to="/" />
        }
        return(
            <div>
                <h2>Login</h2>
                <Form>
                    <Form.Item>
                        <Input
                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            name="username"
                            placeholder="Username"
                            onChange={this.handleChange}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Input
                            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            name="password"
                            type="password"
                            placeholder="Password"
                            onChange={this.handleChange}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button onClick={this.handleLogin} type="primary">
                            Login
                        </Button>
                    </Form.Item>
                </Form>
                <Modal
                    title="Error on login"
                    visible={this.state.loginFailed}
                    onOk={this.handleModal.bind(this)}
                    onCancel={this.handleModal.bind(this)}
                >
                    <p>The provided credentials are not valid, please try again.</p>
                </Modal>
            </div>
        )
    }
}

export default Login